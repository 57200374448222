import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, ButtonBase, Collapse, IconButton, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";

export default function RightContent(
    { selectedCategory,
        categories,
        expandedQuestions,
        toggleQuestion
    }:
        {
            selectedCategory: string,
            categories: { name: string, questions: { question: string, answer: string }[] }[],
            expandedQuestions: { [key: string]: boolean },
            toggleQuestion: (question: string) => void
        }
) {

    return (
        <>
            <Box sx={{ flexGrow: 1, overflowY: 'auto', marginBottom: '100px', paddingBottom: '40px', marginLeft: '50px' }}>
                <Typography variant="h4" sx={{ marginBottom: '30px', fontWeight: '800' }}>{selectedCategory}</Typography>
                {categories.find(cat => cat.name === selectedCategory)?.questions.map(question => (
                    <Box key={question.question} sx={{ marginBottom: '20px' }}>
                        <ButtonBase onClick={() => toggleQuestion(question.question)} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                            <Typography variant="h6">{question.question}</Typography>
                            <IconButton>
                                {expandedQuestions[question.question] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                            </IconButton>
                        </ButtonBase>
                        <Collapse in={expandedQuestions[question.question]} timeout="auto" unmountOnExit>
                            <Typography variant="body1" sx={{ marginTop: '10px' }} className='markdown'>
                                <ReactMarkdown remarkPlugins={[remarkMath, remarkGfm]} rehypePlugins={[rehypeKatex]} components={{
                                    img: ({ node, ...props }) => (
                                        <img
                                            {...props}
                                            style={{ maxWidth: '100%', height: 'auto' }}
                                        />
                                    ),
                                }}
                                >
                                    {question.answer}
                                </ReactMarkdown>
                            </Typography>
                        </Collapse>
                    </Box>
                ))}
            </Box>

        </>
    );


}
