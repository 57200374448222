import axios from "axios";

export enum ApiEndpoints {
    // Consolidated the api endpoint strings here
    SLICE_IMAGE_PNG = "/app/slice/image/png",
    CHECK_USER = "/app/get_user_access_status",
    ACCEPT_TOS = "/app/accept_terms_of_service",
    SCAN_LIST = "/app/scan/list",
    SLICE_LIST = "/app/slice/list",
    SCAN_IMAGES_ZIP = "/app/scan/all_images/zip_blob",
    SCAN_METADATA = "/app/scan/customer_context_data",
    DEMO_SCAN_LIST = "/app/scan-demo/list",
    DEMO_SLICE_LIST = "/app/slice-demo/list",
    DEMO_SCAN_IMAGES_ZIP = "/app/scan-demo/all_images/zip_blob",
    // inspection v2:
    INSPECTION_METRICS = '/app/inspection/metrics',
    INSPECTION_RESULTS_GENERAL = '/app/inspection/results/slices/general',
    INSPECTION_RESULTS_AXIAL_CATHODE_TRACE = '/app/inspection/results/slices/axial/cathode_trace',
    INSPECTION_RESULTS_AXIAL_ELECTRODE_OVERHANG_TRACE = '/app/inspection/results/slices/axial/electrode_overhang_trace',
    INSPECTION_CHART_RESULTS_GENERAL = '/app/inspection/results/scans/by-metric',
    // internal pages:
    INTERNAL_CELL_MODEL = "/app/internal/cell_model",
    INTERNAL_SCAN_TYPE = "/app/internal/scan_type",
    INTERNAL_REQUESTS = "/app/internal/request",
    INTERNAL_REQUEST_GROUP = "/app/internal/request_group",
    INTERNAL_MEMBERSHIPS = "/app/internal/membership",
    INTERNAL_USERS = "/app/internal/user",
    INTERNAL_SCANNERS = "/app/internal/scanner",
    INTERNAL_SCANS = "/app/internal/scan",
    INTERNAL_COMPANIES = "/app/internal/company",
    INTERNAL_TOS_RECORDS = "/app/internal/tos_records",
    INTERNAL_CELLS = "/app/internal/cell",
    INTERNAL_GLIMPSEBOXES = "/app/internal/glimpseboxes",
    INTERNAL_TOGGLE_SCAN_FLAG = "/app/internal/toggle-scan-flag",
    INTERNAL_USER_LOGS = "/app/internal/user_logs",
    // operator pages:
    OPERATOR_LOGS = "/app/operator/scan-logs",
    OPERATOR_QUEUES = "/app/operator/scans-queue",
}

type ParamsType = { [key: string]: any };
export const axiosConfigBase = (token: string, params: ParamsType = {}) => {
    return {
        headers: {
            Authorization: "Bearer " + token
        },
        baseURL: process.env.REACT_APP_API_BASEURL as string,
        params: params
    };
};
// could probably make this more flexible
export const axiosConfigScanId = (token: string, scan_id: number) => {
    return {
        headers: {
            Authorization: "Bearer " + token
        },
        baseURL: process.env.REACT_APP_API_BASEURL as string,
        params: { scan_id: scan_id },
    }
};


export const loadOneImageFromApi = async (token: string, slice_id: number, controller: AbortController) => {
    // TODO Deprecate? this could be useful later.
    const response = await axios.get(
        ApiEndpoints.SLICE_IMAGE_PNG,
        {
            headers: {
                Authorization: "Bearer " + token
            },
            baseURL: process.env.REACT_APP_API_BASEURL as string,
            params: { slice_id: slice_id },
            responseType: 'blob',
            signal: controller.signal
        }
    ).catch((error) => {
        if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
        } else {
            // handle other errors
        }
    });
    if (response) {
        return response.data
    }
    // Else throw error or something, I'll deal with that later
    return response
    // TODO: error guards here yikes *grimace emoji* good lord this is brittle
}
