import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Typography } from "@mui/material";
import { ScanMetadata } from "../../types";
import { borderColor } from '../../utils/tableFormats';


export default function CustomerScanContextData(
    { scanMetadata, isCustomerScanContextOpen, toggleScanContext
    }:
        {
            scanMetadata: ScanMetadata[],
            isCustomerScanContextOpen: boolean,
            toggleScanContext: () => void,
        }
) {

    const borderBottom = { borderBottom: "solid 0.5px", borderColor: borderColor }

    return (
        <>
            <Box
                sx={{
                    position: 'fixed',
                    top: 60,
                    right: isCustomerScanContextOpen ? 0 : '-300%',
                    width: '20%',
                    height: '100%',
                    boxShadow: '-2px 0px 5px rgba(0,0,0,0.5)',
                    overflowY: 'auto',
                    padding: 4,
                    backgroundColor: "#192A51",
                }}>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '16px',
                        right: '16px',
                    }}
                    onClick={toggleScanContext}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h5">Scan Context</Typography>
                {
                    scanMetadata && scanMetadata.length > 0 &&
                    scanMetadata.map((metadata: ScanMetadata) => {
                        return (
                            <Box key={metadata.field} sx={{ borderBottom: borderBottom, marginTop: 2 }}>
                                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                                    {metadata.field}:
                                </Typography>
                                <Typography variant="body2" marginBottom={2}>
                                    {metadata.value.toString()}
                                </Typography>
                            </Box>
                        )
                    })
                }
            </Box>
        </>
    );


}
