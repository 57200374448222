import { Box, ButtonBase, List, ListItem, Typography } from "@mui/material";



export default function NavBar(
    { selectedCategory, setSelectedCategory, categories,
    }:
        {
            selectedCategory: string,
            setSelectedCategory: (category: string) => void,
            categories: { name: string }[]
        }
) {

    return (
        <>
            <Box sx={{
                minWidth: "250px",
                borderRight: "1px solid #ccc",
                position: "sticky",
                height: "calc(100vh - 64px)"
            }}>
                <List>
                    {categories.map((category) => (
                        <ListItem component={ButtonBase}
                            onClick={() => setSelectedCategory(category.name)}>
                            {selectedCategory === category.name && (
                                <Box
                                    sx={{
                                        width: 8,
                                        height: 8,
                                        borderRadius: '50%',
                                        backgroundColor: "#77C5FF",
                                        marginRight: 1,
                                    }}
                                />
                            )}
                            <Typography
                                variant="body1"
                                style={{
                                    fontWeight: selectedCategory === category.name ? '900' : 'normal',
                                }}
                            >
                                {category.name}
                            </Typography>
                        </ListItem>
                    ))}
                </List>
            </Box>

        </>
    );


}
