import useUrlState from "@ahooksjs/use-url-state";
import { Button, Grid } from "@mui/material";
import { throttle } from 'lodash';
import { useCallback, useEffect, useState } from "react";
import { MeasurementDimensions, ScanAxes, ScanInfo, ScanMetadata, SliceOrientation, SlicePositionsCyl, SlicePositionsXyz, SliceRow } from "../../types";
import { middleSlicePosition } from "../../utils";
import OneAxisSliceView from "./OneAxisSliceView";
import ScanMetaData from "./ScanMetaData";
export default function SliceDisplayManager(
    {
        scanData,
        sliceList,
        scanAxes,
        isDemoMode,
        availableMetrics,
        scanMetadata,
        toggleScanContext
    }: {
        scanData: ScanInfo,
        sliceList: SliceRow[],
        scanAxes: ScanAxes,
        isDemoMode: boolean
        availableMetrics: MeasurementDimensions[],
        scanMetadata: ScanMetadata[]
        toggleScanContext: () => void
    }
) {
    const [sliceOrientationList, setSliceOrientationList] = useState([] as SliceOrientation[])
    const [slicePositions, setSlicePositions] = useState({ axial: 0, radial: 0 } as SlicePositionsCyl | SlicePositionsXyz)
    const [urlStore, setUrlStore] = useUrlState()

    useEffect(() => {
        if (sliceList.length > 0) {
            // check if positions are in slice list, if not, set to middle slice
            if (scanAxes === ScanAxes.CYL) {
                // clear the url state for the other axes, cleaner url if they're switching between axes types (edge case)
                setUrlStore({ xy: undefined, yz: undefined, xz: undefined })
                let axial = middleSlicePosition(SliceOrientation.AXIAL, sliceList)
                let radial = middleSlicePosition(SliceOrientation.RADIAL, sliceList)
                if (urlStore?.axial &&
                    sliceList.filter((slice: SliceRow) => slice.orientation === SliceOrientation.AXIAL).map((slice: SliceRow) => slice.position).includes(Number(urlStore.axial))) {
                    axial = Number(urlStore.axial)
                }
                if (urlStore?.radial && sliceList.filter((slice: SliceRow) => slice.orientation === SliceOrientation.RADIAL).map((slice: SliceRow) => slice.position).includes(Number(urlStore.radial))) {
                    radial = Number(urlStore.radial)
                }
                setSliceOrientationList([SliceOrientation.RADIAL])
                setSlicePositions({ radial: radial, axial: axial })
            }
            if (scanAxes === ScanAxes.XYZ) {
                let xy = middleSlicePosition(SliceOrientation.XY, sliceList)
                let xz = middleSlicePosition(SliceOrientation.XZ, sliceList)
                let yz = middleSlicePosition(SliceOrientation.YZ, sliceList)
                if (urlStore?.xy && sliceList.filter((slice: SliceRow) => slice.orientation === SliceOrientation.XY).map((slice: SliceRow) => slice.position).includes(Number(urlStore.xy))) {
                    xy = Number(urlStore.xy)
                }
                if (urlStore?.xz && sliceList.filter((slice: SliceRow) => slice.orientation === SliceOrientation.XZ).map((slice: SliceRow) => slice.position).includes(Number(urlStore.xz))) {
                    xz = Number(urlStore.xz)
                }
                if (urlStore?.yz && sliceList.filter((slice: SliceRow) => slice.orientation === SliceOrientation.YZ).map((slice: SliceRow) => slice.position).includes(Number(urlStore.yz))) {
                    yz = Number(urlStore.yz)
                }
                setUrlStore({ axial: undefined, radial: undefined })
                setSlicePositions({ xy: xy, xz: xz, yz: yz })
                setSliceOrientationList([SliceOrientation.XY])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sliceList])

    const updateSlicePositions = (position: number, orientation: SliceOrientation) => {
        const update = { ...slicePositions, [orientation]: position }
        throttleSlicePosition(update)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const throttleSlicePosition = useCallback(throttle((update) => {
        setSlicePositions(update); setUrlStore(update)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, 100), [])

    const loadNextOrientation = (previous: SliceOrientation) => {
        if (scanAxes === ScanAxes.CYL) {
            if (previous === SliceOrientation.RADIAL) {
                setSliceOrientationList([SliceOrientation.RADIAL, SliceOrientation.AXIAL])
            }
        }
        if (scanAxes === ScanAxes.XYZ) {
            if (previous === SliceOrientation.XY) {
                setSliceOrientationList([SliceOrientation.XY, SliceOrientation.XZ])
            }
            if (previous === SliceOrientation.XZ) {
                setSliceOrientationList([SliceOrientation.XY, SliceOrientation.XZ, SliceOrientation.YZ])
            }
        }
    }

    const minSlicePositions = () => {
        if (scanAxes === ScanAxes.CYL) {
            return {
                axial: Math.min(...sliceList.filter((slice: SliceRow) => slice.orientation === SliceOrientation.AXIAL)
                    .map((slice: SliceRow) => slice.position)),
                radial: Math.min(...sliceList.filter((slice: SliceRow) => slice.orientation === SliceOrientation.RADIAL)
                    .map((slice: SliceRow) => slice.position))
            } as SlicePositionsCyl
        }
        if (scanAxes === ScanAxes.XYZ) {
            return {
                xy: Math.min(...sliceList.filter((slice: SliceRow) => slice.orientation === SliceOrientation.XY).map((slice: SliceRow) => slice.position)),
                xz: Math.min(...sliceList.filter((slice: SliceRow) => slice.orientation === SliceOrientation.XZ).map((slice: SliceRow) => slice.position)),
                yz: Math.min(...sliceList.filter((slice: SliceRow) => slice.orientation === SliceOrientation.YZ).map((slice: SliceRow) => slice.position))
            } as SlicePositionsXyz
        }
        return {} as SlicePositionsCyl
    }


    const xlScreenGrid = () => { return scanAxes === ScanAxes.XYZ ? 4 : 6 }
    if (sliceOrientationList.length > 0 && minSlicePositions()) return (
        <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                <ScanMetaData scanData={scanData} voxelSize={sliceList[0].voxel_size_mm} />
                {
                    scanMetadata && scanMetadata.length > 0 &&
                    <Button
                        sx={{
                            backgroundColor: "#192A51",
                            height: "71px",
                            fontSize: "11px",
                            marginLeft: '10px', // space between elements
                        }}
                        onClick={toggleScanContext}
                    >
                        Scan <br />Context
                    </Button>
                }
            </div>


            <Grid container
                spacing={1}
                direction="row"
                justifyContent="center"
                sx={{ mb: 3 }}
            >
                {
                    sliceOrientationList.map((sliceOrientation: SliceOrientation) => {
                        return (
                            <Grid key={sliceOrientation} item xs={12} md={6} xl={xlScreenGrid()}>
                                <OneAxisSliceView
                                    sliceList={sliceList.filter((slice: SliceRow) => slice.orientation === sliceOrientation)}
                                    sliceOrientation={sliceOrientation}
                                    scanData={scanData}
                                    handleSliderChange={(e, newValue) => { updateSlicePositions(newValue as number, sliceOrientation) }}
                                    slicePositions={slicePositions}
                                    handleLoadComplete={loadNextOrientation}
                                    minSlicePositions={minSlicePositions()}
                                    isDemoMode={isDemoMode}
                                    availableMetrics={availableMetrics}
                                    toggleScanContext={toggleScanContext}
                                // TODO: remove ^ action down, replace with data down.
                                />
                            </Grid>
                        )
                    })
                }
                {
                    // This is kinda dumb but whatever- makes it look nice
                    sliceOrientationList.length === 1 &&
                    <Grid item xs={12} md={6} xl={xlScreenGrid()}></Grid>
                }
                {
                    sliceOrientationList.length >= 1 && sliceOrientationList.length < 3 && scanAxes === ScanAxes.XYZ &&
                    <Grid item xs={12} md={6} xl={xlScreenGrid()}></Grid>
                }
            </Grid>
        </>
    )
    return (
        null
    );
}
